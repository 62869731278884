/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useRef } from 'react'
import classNames from 'classnames/bind'
import { getIn } from 'formik'
import { DateTime } from 'luxon'
import { sortBy } from 'lodash'

// React phone number input uses libphonenumber-js under the hood
// Importing country code and E146Number for types
import formatTaxNumber from 'lib/format-tax-number'
import { CountryCode, E164Number } from 'libphonenumber-js/core'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

import useForm from 'components/Form/useForm'
import Icon from 'components/Icon'
import DatePicker from 'components/DatePicker'
import TagPicker from 'components/TagPicker'
import TypeAheadDropDown from 'components/TypeAheadDropDown'
import DebouncedTextArea from './DebouncedTextArea'
import DebouncedInput from './DebouncedInput'
import CheckboxDropdown from 'components/CheckboxDropdown'
import Switch from 'components/Switch'
import CheckboxRadio from 'components/CheckboxRadio'
import CheckboxList from 'components/CheckboxList'
import FuzzyFinder from 'components/FuzzyFinder'
import { FuzzyOptions, FuzzyFinderProps } from 'components/FuzzyFinder/FuzzyFinder'
import { TypeAheadOptions } from 'components/TypeAheadDropDown/TypeAheadDropDown'
import SlugPicker from 'components/SlugPicker'
import CardSelector from 'components/CardSelector'
import AddressAutocomplete from 'components/AddressAutocomplete'
import { AddressValueProps, LocationValueProps } from 'components/AddressAutocomplete/AddressAutocomplete'
import TelephoneInput from 'components/TelephoneInput'
import CreditCardInput from 'components/CreditCardInput'
import { CreditCardValueProps } from 'components/CreditCardInput/CreditCardInput'
import BusinessNumberResultsCard from 'modules/signup/components/BusinessNumberResultsCard'
import AddressPicker from 'components/AddressPicker'
import { AddressPickersValueProps } from 'components/AddressPicker/AddressPicker'
import DropdownSelect, { DropdownSelectOption } from 'components/DropdownSelect/DropdownSelect'
import TileList from 'components/TileList'
import { CheckboxKind } from 'components/CheckboxRadio/CheckboxRadio'
import RegionCheckboxDropdown from 'components/RegionCheckboxDropdown'

import styles from './FormControls.module.css'
import CodeInput from 'components/CodeInput'

const cx = classNames.bind(styles)

const Option = ({ option }: Option) =>
  typeof option === 'string' ? (
    <option value={option}>{option}</option>
  ) : (
    <option value={option?.value}>{option?.label}</option>
  )

export type Option = { value: any } | any

export type FormErrors = Record<string, string>
export type FormTouched = Record<string, boolean>

export type FormInputProps = {
  field?: {
    name?: string
    value?: any
    defaultValue?: any
  }
  type: HTMLInputElement['type']
  kind?: 'default' | 'primary' | 'light'
  mode?: 'button' | 'input'
  titlePosition?: 'left' | 'center' | 'right'
  disabled?: boolean
  isReadOnly?: boolean
  autoFocus?: boolean
  active?: boolean
  checked?: boolean
  children?: React.ReactNode
  title?: string | React.ReactNode
  hint?: string | React.ReactNode
  description?: string | React.ReactNode
  descriptionPosition?: 'top' | 'bottom'
  placeholder?: string
  originalValue?: any
  radioValue?: any
  options?: Option[]
  optionsPriority?: Option[]
  onChange?: (args: any) => void
  onClick?: (args: any) => void
  loadFn?: (inputValue: string) => Promise<any>
  maxLength?: number
  className?: string
  useDebounced?: boolean
  debounceWait?: number
  iconLeft?: string
  iconRight?: string
  cxIconRight?: string
  hasManualMode?: boolean
  taxCountry?: string
  inputProps?: {
    min?: number | string
    step?: number | string
  }
  inputTextProps?: {
    className?: string
  }
  switchProps?: {
    reverse?: boolean
  }
  cardSelectorProps?: {
    className?: string
    maxSelectedItems?: number
  }
  businessNumberResultsCardProps?: {
    className?: string
  }
  checkboxListProps?: {
    numColumns?: number
    className?: string
    mode?: 'checkbox' | 'switch'
  }
  tileListProps?: {
    checkboxLabelStyle?: string
    checkboxKind?: CheckboxKind
  }
  regionCheckboxDropdownProps?: {
    region: 'AU' | 'NZ'
    disabledOptions?: string[]
    checkboxKind?: CheckboxKind
  }
  form?: {
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    isSubmitting?: boolean
    touched?: FormTouched
    errors?: FormErrors
    values?: Record<string, string | number>
    initialValues?: Record<string, string | number>
    submitForm?: () => void
  }
  prefix?: string
  isSingleChoice?: boolean
  defaultCountry?: CountryCode
  usePriorityCountries?: boolean
  hideEditButton?: boolean
  hideAddButton?: boolean
  alignRight?: boolean
  fuzzyFinderProps?: FuzzyFinderProps
}

/**
 * @info This component provides a set of inputs which respect certain props which Formik will pass - i.e. for form state, errors etc.
 *
 * TODO - probably refactor this, so we have separate files for each 'type'
 */

const FormInput = ({
  children,
  field = { name: '', value: '' },
  form: { isSubmitting = false, setFieldValue, touched, errors, initialValues, values, submitForm } = {},
  type = 'text',
  kind = 'default',
  mode = 'input',
  titlePosition = 'left',
  title,
  hint,
  description,
  descriptionPosition = 'bottom',
  originalValue = null,
  checked,
  className,
  useDebounced,
  options,
  optionsPriority,
  disabled,
  isReadOnly,
  active,
  iconLeft,
  iconRight,
  cxIconRight,
  hasManualMode,
  taxCountry,
  inputProps,
  inputTextProps,
  switchProps,
  prefix,
  isSingleChoice,
  cardSelectorProps,
  businessNumberResultsCardProps,
  checkboxListProps,
  tileListProps,
  regionCheckboxDropdownProps,
  defaultCountry,
  usePriorityCountries,
  hideEditButton,
  hideAddButton,
  alignRight = false,
  fuzzyFinderProps,
  ...props
}: FormInputProps) => {
  const fieldError = field?.name && getIn(errors, field.name)
  const isTouched = field?.name && getIn(touched, field.name)
  const initialValue = field?.name && getIn(initialValues, field.name)

  const isError =
    Boolean(fieldError && isTouched) &&
    (type !== 'addressAutocomplete' || (type === 'addressAutocomplete' && hasManualMode === false)) // don't apply isError css class on parent component, otherwise it mucks with with the 'manual' mode for address-autocomplete
  const isUpdated = originalValue !== null && originalValue !== field?.value
  const isDirty = initialValue !== field?.value
  const isActive = Boolean(active)
  const { disabled: formDisabled } = useForm()
  const isDisabled = Boolean(disabled) || isSubmitting || formDisabled

  const utilityClasses = cx(
    {
      isUpdated,
      isError,
      isActive,
      isDisabled,
      hasTitle: Boolean(title),
      hasLeftIcon: Boolean(iconLeft),
      hasRightIcon: Boolean(iconRight)
    },
    {
      [styles[kind]]: true,
      [styles[type]]: true
    }
  )

  const possibleValue = field?.value ?? ''
  const validPhoneNumber = type === 'tel' && isValidPhoneNumber(possibleValue) && isPossiblePhoneNumber(possibleValue)
  const shouldTelRenderWithCountrySelect =
    type === 'tel' && (!field?.value || (field?.value && (isUpdated || isDirty || validPhoneNumber)))

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const telPlainRef = useRef<HTMLInputElement>(null)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const lastTelPlainRef = useRef<number>()

  if (telPlainRef?.current?.selectionStart) {
    lastTelPlainRef.current = telPlainRef?.current?.selectionStart
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const telephoneInputRef = useCallback(
    (telephoneInput: HTMLInputElement) => {
      if (telephoneInput && shouldTelRenderWithCountrySelect) {
        if (lastTelPlainRef.current) {
          // Used to focus back to input field when editing value
          telephoneInput.focus()
          telephoneInput.selectionStart = telephoneInput.selectionEnd = lastTelPlainRef.current
        }
      }
    },
    [shouldTelRenderWithCountrySelect]
  )

  // Not very elegant, but this is to account for special case when we want to flag error of a field(s)
  // but display message elsewhere separately.
  const shouldDisplayErrorLabel = fieldError && typeof fieldError === 'string' && fieldError.trim().length > 0
  const showError = isError && shouldDisplayErrorLabel

  return (
    <div className={cx(styles.fieldOuter, utilityClasses, className)}>
      {(title || showError || hint) && (
        <div
          className={cx(
            styles.fieldTitle,
            [titlePosition],
            { isUpdated, isError, isActive },
            { [styles.isRow]: Boolean(description && descriptionPosition === 'bottom') || props.maxLength }
          )}>
          <div className={styles.titleDescriptionContainer}>
            {title && (
              <label className={styles.fieldTitleInner} htmlFor={field?.name}>
                {title}
              </label>
            )}

            {description && descriptionPosition === 'top' && (
              <div className={cx(styles.fieldDescription, styles.positionTop)}>{description}</div>
            )}
          </div>
          {showError && <div className={styles.fieldError}>{fieldError}</div>}

          {!showError && hint && <div className={styles.fieldTitleHint}>{hint}</div>}

          {!showError && !hint && (type === 'text' || type === 'textarea') && props.maxLength && (
            <div className={styles.fieldTitleHint}>
              {(field?.value || '').toString().length}/{props.maxLength}
            </div>
          )}
        </div>
      )}

      <div className={styles.fieldInner}>
        {iconLeft && <Icon kind={iconLeft} size={16} className={styles.iconLeft} />}

        {type == 'codeInput' && (
          <CodeInput
            {...props}
            onComplete={submitForm}
            onChange={value => {
              setFieldValue?.(field?.name || '', value)
              props.onChange?.(value)
            }}
            value={field?.value}
          />
        )}

        {type == 'creditCardInput' && (
          <CreditCardInput
            {...props}
            className={styles.fieldInput}
            disabled={isSubmitting || disabled}
            kind={kind === 'primary' ? 'primary' : 'default'}
            hasError={Boolean(fieldError)}
            onChange={(item: CreditCardValueProps) => {
              setFieldValue?.(field?.name || '', item)
              props.onChange?.(item)
            }}
            value={field?.value}
            name={field?.name ?? 'creditCardInput'}
          />
        )}

        {type == 'addressPicker' && (
          <AddressPicker
            {...props}
            options={options ? options : []}
            disabled={isSubmitting || disabled}
            kind={kind === 'primary' ? 'primary' : 'default'}
            onChange={(item: AddressPickersValueProps) => {
              if (item) {
                setFieldValue?.(field?.name || '', item.value)
                props.onChange?.(item)
              }
            }}
            value={field?.value}
            hideEditButton={hideEditButton}
            hideAddButton={hideAddButton}
          />
        )}

        {type == 'addressAutocomplete' && (
          <AddressAutocomplete
            {...props}
            disabled={isSubmitting || disabled}
            isTouched={isTouched}
            kind={kind === 'primary' ? 'primary' : 'default'}
            hasError={Boolean(fieldError)}
            onChange={(item: AddressValueProps | LocationValueProps | null) => {
              setFieldValue?.(field?.name || '', item)
              props.onChange?.(item)
            }}
            value={field?.value}
            name={field?.name}
            hasManualMode={hasManualMode}
          />
        )}

        {type == 'fuzzyfinder' && (
          <FuzzyFinder
            {...props}
            disabled={isDisabled}
            options={options ?? []}
            onChange={(selectedItems: FuzzyOptions) => {
              if (selectedItems) {
                setFieldValue?.(field?.name || '', selectedItems.value)
                props.onChange?.(selectedItems.value)
              }
            }}
            value={options?.filter(option => option.value === field?.value)}
            kind={kind === 'primary' ? 'primary' : 'default'}
            isError={!!fieldError}
            isTouched={isTouched}
            {...fuzzyFinderProps}
          />
        )}

        {type == 'typeahead' && (
          <TypeAheadDropDown
            {...props}
            renderAs="input"
            className={styles.fieldInput}
            isError={!!fieldError}
            id={field?.name || ''}
            value={field?.value}
            onChange={(selectedItem: TypeAheadOptions) => {
              setFieldValue && setFieldValue(field?.name || '', selectedItem.value)
              props.onChange && props.onChange(selectedItem)
            }}
            disabled={isDisabled}
            loadFn={props.loadFn}
          />
        )}

        {type == 'tagpicker' && (
          <TagPicker
            {...props}
            options={options}
            className={styles.fieldInput}
            isError={!!fieldError}
            value={field?.value}
            onChange={tags => {
              setFieldValue?.(field?.name || '', tags)
              props.onChange?.(tags)
            }}
            disabled={isDisabled}
          />
        )}

        {type === 'datepicker' && (
          <DatePicker
            {...props}
            id={field?.name || ''}
            name={field?.name || ''}
            value={field?.value}
            onChange={value => {
              const val = value ? DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') : ''
              setFieldValue && setFieldValue(field?.name || '', val)
              props.onChange && props.onChange(value)
            }}
            disabled={isDisabled}
          />
        )}

        {type === 'textarea' && (
          <>
            {useDebounced && (
              <DebouncedTextArea
                className={cx(styles.fieldInput, styles.textArea)}
                disabled={isDisabled}
                id={field?.name || ''}
                {...field}
                {...props}
              />
            )}
            {!useDebounced && (
              <textarea
                className={cx(styles.fieldInput, styles.textArea)}
                disabled={isDisabled}
                id={field?.name || ''}
                {...field}
                {...props}
              />
            )}
          </>
        )}

        {(type === 'text' || type === 'email' || type === 'password' || type == 'number') && (
          <>
            {useDebounced && (
              <DebouncedInput
                type={type}
                className={cx(
                  styles.fieldInput,
                  { [styles.alignRight]: alignRight, [styles.readOnly]: isReadOnly },
                  inputTextProps?.className
                )}
                {...field}
                {...inputProps}
                {...props}
                id={field?.name || ''}
                value={
                  field.value != null
                    ? field.name === 'taxNumber' && taxCountry
                      ? formatTaxNumber({ taxNumber: field.value, country: taxCountry })
                      : field.value
                    : ''
                }
                readOnly={isReadOnly}
                disabled={isDisabled}
              />
            )}
            {!useDebounced && (
              <input
                type={type}
                className={cx(
                  styles.fieldInput,
                  { [styles.alignRight]: alignRight, [styles.readOnly]: isReadOnly },
                  inputTextProps?.className
                )}
                {...field}
                {...inputProps}
                {...props}
                id={field?.name || ''}
                value={
                  field.value != null
                    ? field.name === 'taxNumber' && taxCountry
                      ? formatTaxNumber({ taxNumber: field.value, country: taxCountry })
                      : field.value
                    : ''
                }
                readOnly={isReadOnly}
                disabled={isDisabled}
                onChange={event => {
                  const value = event.currentTarget.value
                  setFieldValue && setFieldValue(field?.name || '', value)
                  props.onChange && props.onChange(event)
                }}
              />
            )}
          </>
        )}

        {type === 'hidden' && <input type={type} {...field} value={field.value != null ? field.value : ''} />}

        {type === 'slugpicker' && (
          <SlugPicker
            prefix={prefix}
            className={styles.fieldInput}
            {...field}
            {...inputProps}
            {...props}
            id={field?.name || ''}
            value={field.value != null ? field.value : ''}
            disabled={isDisabled}
            onChange={slug => {
              setFieldValue && setFieldValue(field?.name || '', slug)
              props.onChange && props.onChange(slug)
            }}
          />
        )}

        {type === 'select' && (
          <DropdownSelect
            {...props}
            id={field?.name || ''}
            kind={kind as 'default' | 'primary' | 'secondary'}
            mode={mode}
            value={field.value}
            optionsPriority={optionsPriority}
            options={options}
            onChange={(option: DropdownSelectOption) => {
              setFieldValue && setFieldValue(field?.name || '', option.value)
              props.onChange && props.onChange(option)
            }}
            disabled={isDisabled}
            isError={!!fieldError}
            isTouched={isTouched}
            isReadOnly={isReadOnly}
            className={styles.fieldInput}
          />
        )}

        {type === 'regioncheckboxdropdown' && (
          <RegionCheckboxDropdown
            {...props}
            region={regionCheckboxDropdownProps?.region ?? 'AU'}
            disabledOptions={regionCheckboxDropdownProps?.disabledOptions ?? []}
            checkboxKind={regionCheckboxDropdownProps?.checkboxKind ?? 'default'}
            kind={kind}
            value={(field?.value || []).map((v: any) => {
              return { value: v }
            })}
            onChange={(selectedItems: Option) => {
              const values = selectedItems.map((i: Option) => i.value)
              setFieldValue && setFieldValue(field?.name || '', values)
              props.onChange && props.onChange(values)
            }}
            hasError={Boolean(fieldError)}
            isSubmitting={isSubmitting}
            disabled={isDisabled}
          />
        )}

        {type === 'checkboxdropdown' && (
          <CheckboxDropdown
            {...props}
            options={sortBy(options, [val => val.value.toLowerCase()], ['desc'])}
            id={field?.name || ''}
            name={field?.name || ''}
            kind={kind}
            value={(field?.value || []).map((v: any) => {
              return { value: v }
            })}
            onChange={(selectedItems: Option) => {
              if (isSingleChoice) {
                if (field.value && selectedItems.value === field.value[0]) {
                  // Unselect item
                  setFieldValue && setFieldValue(field?.name || '', [])
                  props.onChange && props.onChange([])
                } else {
                  // Select item
                  setFieldValue && setFieldValue(field?.name || '', [selectedItems.value])
                  props.onChange && props.onChange([selectedItems.value])
                }
              } else {
                const values = selectedItems.map((i: Option) => i.value)
                setFieldValue && setFieldValue(field?.name || '', values)
                props.onChange && props.onChange(values)
              }
            }}
            isSingleChoice={isSingleChoice}
            isDisabled={isSubmitting || disabled}
          />
        )}

        {type === 'checkbox' && (
          <CheckboxRadio
            className={styles.checkRadio}
            type={type}
            kind={kind}
            id={field?.name || ''}
            name={field?.name || ''}
            disabled={isDisabled}
            checked={Boolean(field?.value)}
            onClick={props.onClick}
            onChange={event => {
              setFieldValue && setFieldValue(field?.name || '', !Boolean(field?.value))
              props.onChange && props.onChange(event)
            }}
            label={children}
          />
        )}

        {type === 'radio' && (
          <CheckboxRadio
            className={styles.checkRadio}
            type={type}
            kind={kind}
            id={field?.name || ''}
            name={field?.name || ''}
            disabled={isDisabled}
            checked={checked || values?.[field?.name || ''] === field?.value}
            onClick={props.onClick}
            onChange={event => {
              setFieldValue && setFieldValue(field?.name || '', field?.value)
              props.onChange && props.onChange(event)
            }}
            label={children}
          />
        )}

        {type === 'tilelist' && (
          <TileList
            name={field?.name || ''}
            onChange={selectedItems => {
              setFieldValue && setFieldValue(field?.name || '', selectedItems)
              props.onChange && props.onChange(selectedItems)
            }}
            options={options ? options : []}
            disabled={isDisabled}
            value={field?.value || []}
            onClick={props.onClick}
            checkboxLabelStyle={tileListProps?.checkboxLabelStyle}
            checkboxKind={tileListProps?.checkboxKind}
          />
        )}

        {type === 'checkboxlist' && (
          <CheckboxList
            name={field?.name || ''}
            onChange={selectedItems => {
              setFieldValue && setFieldValue(field?.name || '', selectedItems)
              props.onChange && props.onChange(selectedItems)
            }}
            options={options ? options : []}
            disabled={isDisabled}
            value={field?.value || []}
            onClick={props.onClick}
            numColumns={checkboxListProps?.numColumns}
            className={checkboxListProps?.className}
            mode={checkboxListProps?.mode}
          />
        )}

        {type === 'cardSelector' && (
          <CardSelector
            name={field?.name || ''}
            onClick={selectedItems => {
              setFieldValue && setFieldValue(field?.name || '', selectedItems)
              props.onClick && props.onClick(selectedItems)
            }}
            className={cardSelectorProps?.className ? cardSelectorProps.className : ''}
            cardsInfo={options ? options : []}
            value={field?.value || []}
            maxSelectedItems={cardSelectorProps?.maxSelectedItems}
          />
        )}

        {type === 'businessNumberResultsCard' && (
          <BusinessNumberResultsCard
            name={field?.name || ''}
            onClick={selectedItem => {
              setFieldValue && setFieldValue(field?.name || '', selectedItem)
              props.onClick && props.onClick(selectedItem)
            }}
            className={businessNumberResultsCardProps?.className ?? ''}
            businessCardsInfo={options ? options : []}
            value={field?.value || ''}
          />
        )}

        {type === 'switch' && (
          <Switch
            id={field.name || ''}
            name={field.name || ''}
            disabled={isDisabled}
            checked={checked || field?.value === (switchProps?.reverse ? false : true)}
            onClick={event => {
              setFieldValue?.(field?.name || '', !field?.value)
              props?.onClick?.(event)
            }}
            onChange={props.onChange || (() => undefined)}
            label={children}
          />
        )}

        {type === 'tel' && shouldTelRenderWithCountrySelect && (
          <TelephoneInput
            ref={telephoneInputRef}
            className={styles.fieldInput}
            {...field}
            {...inputProps}
            {...props}
            id={field?.name || ''}
            name={field?.name || ''}
            value={field.value ?? ''}
            disabled={isDisabled}
            setValue={(value: E164Number) => {
              setFieldValue && setFieldValue(field?.name || '', value)
              props?.onChange?.(value)
            }}
            defaultCountry={defaultCountry}
            usePriorityCountries={usePriorityCountries}
          />
        )}

        {type === 'tel' && !shouldTelRenderWithCountrySelect && (
          <input
            ref={telPlainRef}
            type={type}
            className={styles.fieldInput}
            {...field}
            {...inputProps}
            {...props}
            id={field?.name || ''}
            value={field.value ?? ''}
            disabled={isDisabled}
            onChange={event => {
              const value = event.currentTarget.value
              const validRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

              if (value === '' || validRegex.test(value)) {
                setFieldValue && setFieldValue(field?.name || '', value)
                props.onChange && props.onChange(event)
              }
            }}
          />
        )}

        {iconRight && <Icon kind={iconRight} size={16} className={cx(styles.iconRight, cxIconRight)} />}
      </div>

      {description && descriptionPosition === 'bottom' && (
        <div className={cx(styles.fieldDescription, styles.positionBottom)}>{description}</div>
      )}
    </div>
  )
}

export default FormInput
